import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import jiva_logo from '../../assets/images/projects/service/tech/jiva-logo.png'
import tecne_logo from '../../assets/images/projects/service/tecne/tecne-logo.jpg'
import pic04 from '../../assets/images/projects/3.png'
import video1 from '../../assets/videos/bintang1.mp4'
import video2 from '../../assets/videos/bintang2.mp4'
import resume from '../../assets/CV/progression-casestudy.pdf'
import { useRef } from 'react'





const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignTecne = props => {
  const outcomeDiv = useRef(null);

  const scrolLWithUseRef = () => outcomeDiv.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  return (
    <Layout>
      <Helmet>
        <title>Service Design - Progression system</title>
        <meta name="Service Design - Progression system" content="Service Design" />
      </Helmet>

      <div id="main" className="alt">
        <section id="work-banner">
          <div>
            <span className="image main">
              <Img fluid={props.data.tecnecover.childImageSharp.fluid} alt="" />
            </span>
          </div>
        </section>

        <div className="project-wrapper">
          <div className="project-content">
            <header>
              <p className="maisontags">Research, UX Strategy, User Experience, Digital UX/UI</p>

              <h1>A multi-faceted 'Progression System' to engages users in diverse ways.</h1>

              <h3>
              We developed a Progression System tailored to different user archetypes, 
              featuring both short and long-term engagement components and rewards. 
              This system enabled Jiva to automate internal processes based on user performance, 
              rewarding top users (MCs) and identifying underperformers for support or removal.
              <br />
              <br />
              As a result, we saw improvements in SLA compliance, increased transactions and faster credit recovery timelines.
              </h3>

            </header>


            <section className="header-post">
              <div className="headerpost">
                {/* <div className="headerpost-left">

                <p>
                A summarised version of the project is presented here. 
                <br /> For a much more in-depth information, feel free to download the detailed case study document for this project.
                <br />
                <div className="resume-download">
                <a href={resume} target="_blank" rel="noopener noreferrer" className="resume-download-link" download>
                📘 Download case study</a>
                </div> 
                </p>
                </div> */}

                {/* <div className="headerpost-right">
                <div className="client-image">
                  <img className="span-4" src={jiva_logo} alt="things logo" />
                </div>
                <br />
                <p>
                I worked on this project while working at Jiva Ag, an AgriTtech product company.
                <br />
                <br />
                <a href="/about-jiva"><u>know more about Jiva</u></a>
                </p>
              </div>  */}

              </div>
            </section>

            <div className="process">
              <div className="process-left">
                <h4>
                 Project context
                </h4>
                <p>
                We hypothesized that an engagement system could 
                motivate our users (MCs) to transact more with Jiva.
                </p>
                <p>
                To test this, we needed to design a system tailored 
                to our context and target audience, considering all 
                relevant nuances and constraints.
                </p>
                <p>
                <a href="/about-jiva"><u>know more about Jiva's stakeholder ecosystem</u></a>
                </p>
              </div>

              <div className="process-right">
              <h4>
              Industry              
              </h4>
              <span className="credits-text">
              AgriTech
              </span>
              <br />
              <h4>
              My role
              </h4>
              <span className="credits-text">
              🔍 The Researcher, 🎯 the UX Strategist, ✍️ the Experience Designer, 🎬 the Orchestrator and 📱the UX/UI Designer.
              <br />
              <br />
              I collaborated with Users, Leadership team, Field-ops teams, Operations leads, Business Strategist, Front & Back-end Developers, Product Managers, Researchers and Data Science team.
              </span>
              <br />
              </div>

            </div>


            <section className="challenge-section">

            <section className="fullspan">
                <div className="image">
                  <Img fluid={props.data.tecnechallenge3.childImageSharp.fluid} />
                </div>
                </section>

              {/* <span className="project-step-number">01</span>
              <h2>the challenge</h2> */}

              {/* <br />
              <br />
              <br /> */}

              <section className="fullspan">
              <div className="image">
                  <Img fluid={props.data.tecnechallenge2.childImageSharp.fluid} />
                </div>
              </section>

              <div className="process">
                <div className="process-left">
                  <p className="half-textlarge">
                   Our delivery of value to farmers was hindered by poor performing MCs.
                    
                  </p>
                  <p>
                  We faced a disparity in the performance of our users (MCs), with some excelling while others underperforming. 
                  </p>
                  <p>
                  Poor-performing MCs didn't transact enough or comply with credit SLAs, 
                  hindering our business outcomes and failing to deliver Jiva's value to farmers.
                  </p>
                </div>
                <div className="process-right">
                  <p className="half-textlarge">
                  We lacked an effective way to holistically measure MC performance. 
                  </p>
                  <p>
                  Consequently, some potential high performers lost interest and churned due to insufficient support. 
                  </p>
                  <br />
                  <p>
                  This inefficiency prevented us from optimally managing our internal costs and focusing our efforts on the right MCs.
                  </p>
                </div>


                <br />
                <br />

                
                <section className="fullspan">
                <div className="image">
                  <Img fluid={props.data.tecnechallenge1.childImageSharp.fluid} />
                </div>
                </section>

              </div>

              {/* <div className="changebg">
                <div className="process">
                  <p className="half-textlarge">
                    The idea was to design an Progression System that would...
                  </p>

                  <div className="image">
                    <Img fluid={props.data.tecnechallenge2.childImageSharp.fluid} />
                  </div>
                </div>
              </div> */}

              <div className="project-outcome">
                <span onClick={scrolLWithUseRef} className="project-outcome-link">
              see project outcomes >>
                </span>
              </div>
            </section>

            <section className="process-section">

            <section className="fullspan">
            <div className="image">
                <Img fluid={props.data.tecneprocess100.childImageSharp.fluid} />
              </div>
              </section>
              
              {/* <span className="project-step-number">02</span>
              <h2>the approach</h2> */}

              <p className="full-textlarge">
              {/* To ensure an effective engagement system, we identified potential 
              risks that could cause failure and built sacrificial prototypes to 
              test various elements. 
              <br />
              <br /> */}
              This approach helped us understand what worked,
               what didn't, and how people reacted, thereby 
               reducing overall risk and refining our idea.
              </p>

              <div className="changebg">
              <div className="process">
                <div className="process-left">
                  {/* <h4>Potential risks which needed to be tested.</h4> */}
                  <p>Potential risks which needed to be tested</p>
                </div>

                <div className="image">
                  <Img fluid={props.data.tecneprocess3.childImageSharp.fluid} />
                </div>
              </div>


                <div className="process">
                  <div className="process-right">
                    {/* <h4>Next, we came up with various ideas for the progression system experience.</h4> */}
                    <p>Some of the prototypes we designed to learn which system elements excited users, confused them, or made them nervous.</p>
                  </div>
                  {/* <div className="process-right">
                    <p className="half-textlarge">These were made into prototypes of different fidelity;
                      in order to learn about their desirability,
                      feasibility and viability; whilst minimising the identified risks.
                    </p>
                  </div> */}
                </div>

                <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.tecneprocess4.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess5.childImageSharp.fluid} />
              </Slider>

                {/* <div className="image">
                  <Img fluid={props.data.tecneprocess4.childImageSharp.fluid} />
                </div>

                <div className="image">
                  <Img fluid={props.data.tecneprocess5.childImageSharp.fluid} />
                </div>

                <div className="image">
                  <Img fluid={props.data.tecneprocess6.childImageSharp.fluid} />
                </div> */}

              </div>


              <div className="process">
              <div className="process-left">
              <p className="half-textlarge">
              We learned that growth means different things to different MCs. 
              </p>
              </div> 
              <div className="process-right">
                <p>
                Each user archetype has unique needs, drivers and things that they value;
                 requiring tailored strategies to keep them engaged and motivated.
                 <br />
                 <br />
                 We had to design a progression system that caters to the diverse needs of our users.
                </p>
              </div> 
              </div>


              {/* <p className="full-textlarge">
                prototype and research learnings were synthesised to come up with guiding principles & UX strategy for design
              </p> */}

              {/* <div className="image">
              <Img fluid={props.data.tecneprocess7.childImageSharp.fluid} />
            </div> */}

              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.tecneprocess28.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess288.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess29.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess299.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess30.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess300.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess31.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess311.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess32.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess322.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess33.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess333.childImageSharp.fluid} />
              </Slider>

              {/* <p className="full-textlarge">
                Based on the research sysnthesis, user (MC) archetypes were defined
              </p> */}


              <div className="process">
              <div className="process-left">
              <p>
              While some users need support and assurance to benefit from the program, others gain confidence as soon as they start earning rewards. 
              </p>
              </div>  
              <div className="process-right">
              <p>              
              Many also expressed that such a program would help them see their impact on their communities and farmers' lives.
              </p>
              </div> 
              </div>

              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.tecneprocess7.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess8.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
              </Slider>


              <p className="full-textlarge">
              These insights shaped the guiding principles for designing the various elements of our progression system.
              </p>

              <div className="bluebg">
              <p className="full-textlargebluebg">
              How might we craft personalised engagement journeys that ignite motivation and ensure every MC thrives within our progression system? 
              </p>
              </div>

              <div className="changebg">

              <div className="process">
              <div className="process-left">
                    {/* <h4>How progression should feel to the MCs</h4> */}
                    <p className="half-textlarge">
                    We want to create a system that challenges MCs but still feels attainable.
                  </p>
                  </div>

                <div className="process-right">
                  <p>
                  A system that supports poor performers while offering more growth opportunities to high performers. 
                  <br />
                  <br />
                  We also considered key use cases and contextual nuances 
                  like crop seasonality, sustained poor performance, and 
                  the viability and risk of rewards and benefits.
                  </p>
                </div>

              </div>

              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.tecneprocess10.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess11.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess12.childImageSharp.fluid} />
              </Slider>

              
                {/* <div className="process">
                  <div className="process-right">
                    <p>We also considered key use cases and contextual nuances that could impact the system's design.</p>
                  </div>
                </div> */}

                {/* <div className="image">
                  <Img fluid={props.data.tecneprocess12.childImageSharp.fluid} />
                </div> */}
              </div>


              <div className="process">
                <div className="process-left">
                <p className="half-textlarge">
                We played games and organised ideation workshops.
                </p>
                {/* <p>
                  We drew inspiration from the step-by-step onboarding process used in games to introduce rules, nuances and complex elements to users.
                  <br />
                  <br />
                  We involved all teams across the org. in the design process to get diverse perspectives and ideas.
                  </p> */}
                </div>
                <div className="process-right">
                <p>
                  We drew inspiration from the step-by-step onboarding process 
                  used in games to introduce rules, nuances and complex elements to users.
                  <br />
                  <br />
                  We involved all teams across the org. in the design process 
                  to get diverse perspectives and ideas.
                  </p>
                </div>
              </div>


              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.tecneprocess133.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess14.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess144.childImageSharp.fluid} />
              </Slider>


              <div className="changebg">
                <div className="process">
                  <div className="process-left">
                  <p className="half-textlarge">
                    We explored multiple system designs and ran simulations for various use cases.
                    </p>
                  </div>
                  <div className="process-right">
                    <p>
                    With the Leadership, Engineering and Busines teams, we debated the details 
                    of each system, weighing the pros and cons based on user desirability, 
                    business viability, and execution feasibility.
                    </p>
                  </div>
                </div>


                <Slider className="image-scroll" {...settings}>
                  <Img fluid={props.data.tecneprocess15.childImageSharp.fluid} />
                  <Img fluid={props.data.tecneprocess16.childImageSharp.fluid} />
                  <Img fluid={props.data.tecneprocess17.childImageSharp.fluid} />
                </Slider>

                {/* <div className="process">
                <div className="process-left">
                  <h4>Designing the reward system</h4>
                </div>
                <div className="process-right">
                  <p>
                    Growth means different things for different MC's;
                    financial, operational, social, personal.
                  </p>
                </div>
              </div> */}

              

              <p className="full-textlarge">
              To ensure continuous engagement and commitment from MCs, we developed an 'Engagement Framework' that goes beyond just 'Levels'.
              </p>

              {/* <div className="image">
                <Img fluid={props.data.tecneprocess19.childImageSharp.fluid} />
              </div> */}

              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.tecneprocess19.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess197.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess191.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess192.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess193.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess194.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess195.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess196.childImageSharp.fluid} />
              </Slider>

              {/* <div className="image">
                <Img fluid={props.data.tecneprocess197.childImageSharp.fluid} />
              </div> */}

                  <div className="process">
                 
                  <div className="process-right">
                    <p>
                    We offered a range of rewards and benefits to motivate everyone 
                    at every stage of their journey. 
                    <br />
                    <br />
                    These include transaction-based 
                    benefits, as well as one-time and time-limited rewards.
                    </p>
                  </div>

                </div>

              <div className="image">
                <Img fluid={props.data.tecneprocess18.childImageSharp.fluid} />
              </div>

              </div>



                {/* <div className="process">
                  <div className="process-left">
                    <h4>End to end experience</h4>
                  </div>
                  <div className="process-right">
                    <p>
                      End-to-end Service value proposition and experience for the progression system.
                    </p>
                  </div>
                
              </div> */}

              


              <div className="process">
                <div className="process-left">
                  {/* <h4>Testing</h4> */}
                  <p className="half-textlarge">
                  We conducted a pilot using WhatsApp, and it yeilded positive results.
                  </p>
                </div>
                
                <div className="process-right">
                  
                  <p>
                  We needed to test if our detailed ideas and concepts would work on the ground before investing in engineering efforts.
                  <br />
                  <br />
                  To mitigate risks, we ran a pilot, providing weekly updates to the MCs with their points, penalties, and current star levels.
                  </p>
                </div>
              </div>

              <div className="image">
                  <Img fluid={props.data.tecneprocess21.childImageSharp.fluid} />
              </div>

              <div className="image">
                <Img fluid={props.data.tecneprocess22.childImageSharp.fluid} />
              </div>

              {/* <p className="full-textlarge">
              The pilot yielded promising results, with a higher number of transactions and increased 
              throughput. 
              </p> */}

              <div className="image">
                <Img fluid={props.data.tecneprocess23.childImageSharp.fluid} />
              </div>

              <br />
              <br />

              <div className="process">
              <div className="process-left">
              <p className="half-textlarge">
              With this confidence, we quickly created wireframes and tested it with users.
              </p>
              </div>

                <div className="process-right"> 
                  <p>The goal of the digital touchpoint was to enable users to track their 
                    current level, understand how they have gained or lost points, 
                    and stay informed about the latest schemes to earn more points.</p>
                </div>
              </div>

              <section className="fullspan">

              <div className="image">
                <Img fluid={props.data.tecneprocess24.childImageSharp.fluid} />
              </div>

              </section>
              


              <div className="changebg">
              <div className="process">
                  <div className="process-left">
                  <p className="half-textlarge">
                  Finally, we worked with product managers and operations teams to implement the designs.
                  </p>
                  </div>
                  <div className="process-right">
                  {/* <h4>Metrics & KPIs to track - feedback loop post release of the progression system on the app.</h4> */}
                    <p>
                    Starting with the Minimum Lovable Product (MLP) and progressing to subsequent versions, we set up KPIs and metrics to track its adoption and effectiveness.
                    </p>
                    {/* <ol>
                      <li>Improve % of loans recovery within the defined time frame of 150 days.</li>
                      <br />
                      <li>Reduce the number of overdue days of loan.</li>
                      <br />
                      <li>Improve % of MC cash advance repayment within the defined time frame of 7 days.</li>
                      <br />
                      <li>Cash advance overdue amount (past 7 days).</li>
                      <br />
                      <li>Improve % of Input orders delivered to farmers fully within committed SLA.</li>
                    </ol> */}
                  </div>
                </div>
                

                <div className="image">
                  <Img fluid={props.data.tecneprocess25.childImageSharp.fluid} />
                </div>


                

                {/* <div className="image">
              <Img fluid={props.data.tecneprocess26.childImageSharp.fluid} />
            </div> */}

              </div>

            </section>



            <section ref={outcomeDiv} className="outcome-section">
              <div className="outcome-text-wrapper">

              <div className="image">
                <Img fluid={props.data.tecneoutcome50.childImageSharp.fluid} />
              </div>

                {/* <span className="project-step-number">03</span>
                <h2>the outcome</h2> */}
                <div className="outcome">
                <div className="outcome-left">
                <h3>
                The Progression System was designed in a multi-faceted way that enabled Jiva to tailor engagement experiences at an individual MCs level.
                </h3>
                <p>
                On top of the five Tiers; engagement elemets such as 
                Challenges, Achievements, Side quests and Milestones 
                gave flexibility to tailor engagement experiences at  
                </p>
                </div>
                <div className="outcome-right">
                <h3>
                Jiva was able to automate its internal systems to optimally reward, support or penalise MCs depending on performance.
                </h3>
                <p>
                It helped us reward high-performing MCs to keep them 
                engaged while identifying low performers for additional
                   support or removal, saving costs.  
                </p>
                </div>
              </div>
                
                <div className="video">
                <video controls>
                  <source src={video2} type="video/mp4" />
                </video>
                </div>

                <h3>
                We successfully motivated MCs to transact and comply to our SLAs, this was reflected by an increase in the number of input orders delivered 
                within the SLA and a reducting in the time taken for MCs to deliver inputs
                to farmers. 
                <br />
                   <br />
                Additionally, we shortened the credit recovery time 
                from MCs, allowing faster cash rotation and positively impacting
                 our bottom line.
                </h3>

                <div className="image">
                <Img fluid={props.data.tecneoutcome48.childImageSharp.fluid} />
              </div>

                

              </div>
            </section>


            <section className="process-section">

              <div className="video">
                <video controls>
                  <source src={video1} type="video/mp4" />
                </video>
              </div>

              <section className="fullspan">
              <div className="image">
                <Img fluid={props.data.tecneoutcome45.childImageSharp.fluid} />
              </div>

              <div className="image">
                <Img fluid={props.data.tecneprocess27.childImageSharp.fluid} />
              </div>

              <p>
              We also designed a internal tool for field teams which enabled them to 
              view details about users (MCs) and their performance; and create customised 
              engagement schemes tailored to the unique needs of their regions
              and ability to forecast its potential impact on cost & revenue. 
              </p>

              <div className="image">
                <Img fluid={props.data.tecneoutcome47.childImageSharp.fluid} />
              </div>

              {/* <div className="image">
                <Img fluid={props.data.tecneoutcome46.childImageSharp.fluid} />
              </div> */}
              </section>

              

              {/* <div className="video">
                <video controls>
                  <source src={video2} type="video/mp4" />
                </video>
              </div> */}

            </section>


          </div>
        </div>



        




        <div className="project-wrapper">
          <div className="project-content">
            {/* <div className="changebg">


              <div className="process">
                <div className="process-left">
                  <h4>
                    Problems faced during the project
                    and how they were solved
                  </h4>
                </div>
                <div className="process-right">
                  <p>
                    The challenge was to identify ways in which our product
                    assists the coaches to do their job better and not to
                    replace their role.
                  </p>
                  <p>
                    After collaborating with the coaches we found out our
                    USP which was to equip them with factual data which
                    they could not know during their training sessions;
                    such as data about impact strength and area of impact
                    of each hit.
                  </p>
                </div>
              </div>


              <div className="process">
                <div className="process-right">
                  <p>
                    Another challenge was to ensure that our product did not
                    interfere or distract the current training routines and sessions.
                  </p>
                  <p>
                    The insights from our research allowed us to design various
                    micro & macro interactions that made sure our product
                    complimented the workflow of coaches and trainers.
                    For e.g. Highlight-cards gave quick data insights.
                  </p>
                </div>
              </div>

              <div className="process">
                <div className="process-right">
                  <p>
                    Coaches during training need to see live feed of
                    data of their trainers and also see data of the
                    elapsed time. The use of bluetooth (to connect
                    and exchange data/information between the gloves
                    and mobile app) meant putting considerable strain
                    on the database, resulting in slow loading time.
                  </p>
                  <p>
                    Our solution was to take snapshots (pictures) of
                    the data during training and store that data locally.
                    So  if the user was to scroll back in the timeline,
                    either during or at the end of the training session,
                    instead of calling the data from the server we simply
                    showed the snapshots thus eliminating any loading time.
                  </p>
                </div>
              </div>

              <br />
              <br />
              <div className="process">
                <div className="process-left">
                  <h4>
                    Reflection - what I learnt during the project & what I could have done better.
                  </h4>
                </div>
                <div className="process-right">
                  <p>
                    Getting people to talk about an important, but highly complex topic was difficult.
                  </p>
                  <p>
                    We approached people in different ways (short survey, contextual interview,
                    phone conversation, in-person chats) depending on their comfort. Our
                    strategy involved raising awareness around the topic, and being open
                    to listening to stories about people's personal experiences.
                  </p>
                </div>
              </div>
            </div> */}
            

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <p className="full-text">
              Thank you & keep earning points! <span role="img" aria-labelledby="tecneemoji">⭐🎁</span>
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="full-text">
              <div className="hp-featured__hr-line" />
              <h5>NEXT PROJECT</h5>
            </div>
            <br />
            


          </div>
        </div>


        <section id="two" className="tiles inner">
          {/* <div className="hp-featured__hr-line" />
        <h4>NEXT PROJECT</h4> */}
          <div className="tiles__wrapper">
            <a href="/work/service-design-mercedes">
              <div className="tiles__content">
                <div className="tiles__info">
                  {/* <div className="tiles__number">01</div> */}

                  {/* <h2 className="tiles__title">
                      electric car charging service.
                    </h2> */}

                  <div className="tiles__text">
                    Improving the accessibility and usability of the
                    electric-car charging infrastructure for Mercedes
                    Benz & Smart Electric Car owners.
                  </div>
                  <br />
                  <p className="maisontags-card">Research, Service Design, User Experience, Digital UX/UI, Interaction design</p>
                  {/* <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>USER EXPERIENCE</li>
                        <li>DIGITAL UX/UI</li>
                        <li>INTERACTION DESIGN</li>
                      </ul>
                    </div> */}
                </div>
              </div>
              <div className="tiles__image">
                <img src={pic04} alt="" />
              </div>
            </a>
          </div>
        </section>






      </div>
    </Layout>
  )
}

export default ServiceDesignTecne

export const fluidTecneImage = graphql`
  fragment fluidTecneImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    tecnecover: file(
      relativePath: { eq: "projects/service/progression/cover/progression-cover.png" }
    ) {
      ...fluidTecneImage
    }
    tecne_logo: file(
      relativePath: { eq: "projects/service/tecne/tecne-logo.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecnechallenge1: file(
      relativePath: { eq: "projects/service/progression/challenge/1.png" }
    ) {
      ...fluidTecneImage
    }
    tecnechallenge2: file(
      relativePath: { eq: "projects/service/progression/challenge/2.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecnechallenge3: file(
      relativePath: { eq: "projects/service/progression/challenge/3.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess3: file(
      relativePath: { eq: "projects/service/progression/process/3.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess4: file(
      relativePath: { eq: "projects/service/progression/process/4.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess5: file(
      relativePath: { eq: "projects/service/progression/process/5.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess6: file(
      relativePath: { eq: "projects/service/progression/process/6.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess7: file(
      relativePath: { eq: "projects/service/progression/process/7.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess8: file(
      relativePath: { eq: "projects/service/progression/process/8.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess9: file(
      relativePath: { eq: "projects/service/progression/process/9.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess10: file(
      relativePath: { eq: "projects/service/progression/process/10.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess100: file(
      relativePath: { eq: "projects/service/progression/process/100.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess11: file(
      relativePath: { eq: "projects/service/progression/process/11.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess12: file(
      relativePath: { eq: "projects/service/progression/process/12.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess133: file(
      relativePath: { eq: "projects/service/progression/process/133.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess14: file(
      relativePath: { eq: "projects/service/progression/process/14.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess144: file(
      relativePath: { eq: "projects/service/progression/process/144.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess15: file(
      relativePath: { eq: "projects/service/progression/process/15.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess16: file(
      relativePath: { eq: "projects/service/progression/process/16.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess17: file(
      relativePath: { eq: "projects/service/progression/process/17.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess18: file(
      relativePath: { eq: "projects/service/progression/process/18.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess19: file(
      relativePath: { eq: "projects/service/progression/process/19.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess191: file(
      relativePath: { eq: "projects/service/progression/process/191.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess192: file(
      relativePath: { eq: "projects/service/progression/process/192.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess193: file(
      relativePath: { eq: "projects/service/progression/process/193.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess194: file(
      relativePath: { eq: "projects/service/progression/process/194.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess195: file(
      relativePath: { eq: "projects/service/progression/process/195.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess196: file(
      relativePath: { eq: "projects/service/progression/process/196.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess197: file(
      relativePath: { eq: "projects/service/progression/process/197.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess20: file(
      relativePath: { eq: "projects/service/progression/process/20.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess21: file(
      relativePath: { eq: "projects/service/progression/process/21.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess22: file(
      relativePath: { eq: "projects/service/progression/process/22.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess23: file(
      relativePath: { eq: "projects/service/progression/process/23.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess24: file(
      relativePath: { eq: "projects/service/progression/process/24.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess25: file(
      relativePath: { eq: "projects/service/progression/process/25.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess26: file(
      relativePath: { eq: "projects/service/progression/process/26.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess27: file(
      relativePath: { eq: "projects/service/progression/process/27.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess28: file(
      relativePath: { eq: "projects/service/progression/process/28.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess288: file(
      relativePath: { eq: "projects/service/progression/process/288.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess29: file(
      relativePath: { eq: "projects/service/progression/process/29.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess299: file(
      relativePath: { eq: "projects/service/progression/process/299.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess30: file(
      relativePath: { eq: "projects/service/progression/process/30.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess300: file(
      relativePath: { eq: "projects/service/progression/process/300.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess31: file(
      relativePath: { eq: "projects/service/progression/process/31.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess311: file(
      relativePath: { eq: "projects/service/progression/process/311.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess32: file(
      relativePath: { eq: "projects/service/progression/process/32.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess322: file(
      relativePath: { eq: "projects/service/progression/process/322.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess33: file(
      relativePath: { eq: "projects/service/progression/process/33.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess333: file(
      relativePath: { eq: "projects/service/progression/process/333.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess34: file(
      relativePath: { eq: "projects/service/tecne/process/34.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess35: file(
      relativePath: { eq: "projects/service/tecne/process/35.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess36: file(
      relativePath: { eq: "projects/service/tecne/process/36.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess37: file(
      relativePath: { eq: "projects/service/tecne/process/37.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess38: file(
      relativePath: { eq: "projects/service/tecne/process/38.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess39: file(
      relativePath: { eq: "projects/service/tecne/process/39.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess40: file(
      relativePath: { eq: "projects/service/tecne/process/40.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess41: file(
      relativePath: { eq: "projects/service/tecne/process/41.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess42: file(
      relativePath: { eq: "projects/service/tecne/process/42.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess43: file(
      relativePath: { eq: "projects/service/tecne/process/43.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess44: file(
      relativePath: { eq: "projects/service/tecne/process/44.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome45: file(
      relativePath: { eq: "projects/service/progression/outcome/45.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome47: file(
      relativePath: { eq: "projects/service/progression/outcome/47.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome48: file(
      relativePath: { eq: "projects/service/progression/outcome/48.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome50: file(
      relativePath: { eq: "projects/service/progression/outcome/50.jpg" }
    ) {
      ...fluidTecneImage
    }
    things_logo: file(
      relativePath: { eq: "articles/mobileiot/things-logo.png" }
    ) {
      ...fluidTecneImage
    }
    tecne_process: file(
      relativePath: { eq: "projects/process/tecne-process.png" }
    ) {
      ...fluidTecneImage
    }
    project1: file(relativePath: { eq: "projects/3.png" }) {
      ...fluidTecneImage
    }
    project2: file(relativePath: { eq: "projects/10.png" }) {
      ...fluidTecneImage
    }
  }
`
